/* eslint-disable @typescript-eslint/naming-convention */
export enum ActionTypes {
  SAVE_LOG = '[Log] Save log',

  SET_EXTENSION_ERROR = '[Msg] Set extension error',
  SET_EXTENSION_STATUS = '[Msg] Set extension status',
  SET_IS_EXTENSION_INSTALLED = '[Msg] Set is extension installed',
  SET_IS_MOBILE_APP_INSTALLED = '[Msg] Set is mobile app installed',
  SET_MOBILE_APP_STATUS = '[Msg] Set mobile app status',
  UNKNOWN_MSG = '[Msg] Unknown',

  CHANGE_PAGE_ANSWER = '[Answers] Change page answer',
  CHANGE_PAGE_ANSWER_SUCCESS = '[Answers] Change page answer success',
  SAVE_ANSWERS = '[Answers] Save answers',
  SAVE_ANSWERS_TO_QUEUE = '[Answers] Save answers to queue',
  SAVE_QUEUE = '[Answers] Save queue',
  SAVE_ANSWERS_ERROR = '[Answers] Save answers error',
  SAVE_ANSWERS_SUCCESS = '[Answers] Save answers success',

  SAVE_PAGE_ANSWERS = '[TestRunner] Save page answers',

  INIT_PAGE_SUCCESS = '[Page] Init page success',

  GET_NEXT_PAGE = '[Page] Get next page',
  GET_NEXT_PAGE_SUCCESS = '[Page] Get next page success',

  GET_PREV_PAGE = '[Page] Get prev page',
  GET_PREV_PAGE_SUCCESS = '[Page] Get prev page success',

  GET_TEST_ITEM_MODELS_VISIBILITY_SUCCESS = '[Page] Get test items models visibility success',

  REVALIDATE_PAGE_ANSWERS = '[Page] Revalidate page answers visibility',
  REVALIDATE_PAGE_ANSWERS_SUCCESS = '[Page] Revalidate page answers visibility success',

  LOCK_BTN_NEXT = '[Page] Lock button next',

  GET_CURRENT_PAGE = '[Page] Set current page',
  GET_CURRENT_PAGE_SUCCESS = '[Page] Set current page success',

  GET_BALANCING_STATISTICS_SUCCESS = '[Statistics] Get balancing statistics success',
  GET_LAYOUT_LOGIC_STATISTICS_SUCCESS = '[Statistics] Get layout logic statistics success',
  GET_PAGE_ITEMS_STATISTICS_SUCCESS = '[Statistics] Get page items statistics success',

  BACKEND_ERRORS = '[TestRunner] Backend errors',
  EMPTY = '[TestRunner] Empty',

  GET_SESSION_CACHE = '[TestRunner] Get session cache',

  FINISH_TEST = '[TestRunner] Finish test action',
  FINISH_MOBILE_TEST = '[TestRunner] Finish mobile test action',

  SAVE_SESSION_CACHE = '[TestRunner] Save session cache',
  SAVE_SESSION_CACHE_SUCCESS = '[TestRunner] Save session cache success',

  SCREEN_OUT = '[TestRunner] Screen out',
  SCREEN_OUT_SUCCESS = '[TestRunner] Screen out',

  SET_MEDIA_DEVICES = '[TestRunner] Set media devices',

  SET_EYETRACKER_INFO = '[TestRunner] Set EyeTracker info',

  SET_TEST_INFO = '[TestRunner] Set test info',
  SET_TEST_INFO_SUCCESS = '[TestRunner] Set test info success',

  SCRIPT_ERROR = '[TestRunner] Script error',

  START_MOBILE_SESSION = '[TestRunner] Start mobile session',

  START_SESSION = '[TestRunner] Start session',
  START_SESSION_ERROR = '[TestRunner] Start session error',
  START_SESSION_SUCCESS = '[TestRunner] Start session success',

  STOP_VIDEO_RECORDING = '[TestRunner] Stop video recording',

  FINISH_SESSION = '[TestRunner] Finish session',
  FINISH_SESSION_SUCCESS = '[TestRunner] Finish session success',

  REORDER_LAYOUT_ITEMS = '[TestRunner] Reorder layout items',

  GET_PROMO_CODE = '[TestRunner] Get promo code',
  GET_PROMO_CODE_SUCCESS = '[TestRunner] Get promo code success',
  GET_PROMO_CODE_ERROR = '[TestRunner] Get promo code error',

  GET_TOKEN = '[TestRunner] Get token',

  GET_WEB_SITE_EXTENSION_START_DATA = '[TestRunner] Get web site extension data',
  GET_WEB_SITE_EXTENSION_START_DATA_SUCCESS = '[TestRunner] Get web site extension data success',

  SAVE_EVENTS = '[Telemetry] Save events',
  SAVE_EVENTS_SUCCESS = '[Telemetry] Save events success',

  SAVE_SCREENSHOTS = '[Telemetry] Save screenshots',
  SAVE_SCREENSHOTS_SUCCESS = '[Telemetry] Save screenshots success',

  SAVE_VIDEO = '[Telemetry] Save single video',
  SAVE_VIDEO_SUCCESS = '[Telemetry] Save single video success',

  SAVE_VIDEOS = '[Telemetry] Save videos',
  SAVE_VIDEOS_SUCCESS = '[Telemetry] Save videos success',

  SET_LOADER_STATUS = '[TestRunner] Set Loader status',

  ADD_MODERATED_SESSION_CHAT_MESSAGE = '[Moderated] Add moderated session chat message',
  ADD_MODERATED_SESSION_CHAT_MESSAGE_SUCCESS = '[Moderated] Add moderated session chat message success',
  ADD_MODERATED_SESSION_NOTE = '[Moderated] Add moderated session note',
  ADD_MODERATED_SESSION_NOTE_SUCCESS = '[Moderated] Add moderated session note success',
  DELETE_MODERATED_SESSION_CHAT_MESSAGE = '[Moderated] Delete moderated session chat message',
  DELETE_MODERATED_SESSION_CHAT_MESSAGE_SUCCESS = '[Moderated] Delete moderated session chat message success',
  DELETE_MODERATED_SESSION_NOTE = '[Moderated] Delete moderated session note',
  DELETE_MODERATED_SESSION_NOTE_SUCCESS = '[Moderated] Delete moderated session note success',
  GET_MODERATED_MODERATOR_DATA = '[Moderated] Get moderator data',
  GET_MODERATED_MODERATOR_DATA_SUCCESS = '[Moderated] Get moderator data success',
  GET_MODERATED_ROOM_DATA = '[Moderated] Get room data',
  GET_MODERATED_ROOM_DATA_SUCCESS = '[Moderated] Get room data success',
  GET_MODERATED_SESSION = '[Moderated] Get session',
  GET_MODERATED_SESSION_SUCCESS = '[Moderated] Get session success',
  GET_MODERATED_TEST = '[Moderated] Get test',
  GET_MODERATED_TEST_SUCCESS = '[Moderated] Get test success',
  GET_MODERATED_TEST_AND_SESSION = '[Moderated] Get test and session',
  GET_MODERATED_TEST_AND_ROOM_DATA_AND_SESSION = '[Moderated] Get test and room data and session',
  GET_MODERATED_TOKEN_INFO = '[Moderated] Get token info',
  GET_MODERATED_TOKEN_INFO_SUCCESS = '[Moderated] Get token info success',
  GET_MODERATED_VIEWER_DATA = '[Moderated] Get viewer data',
  INIT_MODERATED_TEST = '[Moderated] Init test',
  SET_MODERATED_SESSION_STATUS = '[Moderated] Set session status',
  SET_MODERATED_SESSION_STATUS_SUCCESS = '[Moderated] Set session status success',
  START_MODERATED_SESSION = '[Moderated] Start session',
  START_MODERATED_SESSION_SUCCESS = '[Moderated] Start session success',
  UPDATE_MODERATED_SESSION_CHAT_MESSAGE = '[Moderated] Update moderated session chat message',
  UPDATE_MODERATED_SESSION_CHAT_MESSAGE_SUCCESS = '[Moderated] Update moderated session chat message success',
  UPDATE_MODERATED_SESSION_NOTE = '[Moderated] Update moderated session note',
  UPDATE_MODERATED_SESSION_NOTE_SUCCESS = '[Moderated] Update moderated session note success',
}
