import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { ActionTypes } from '../action-types';
import {
  TestEndByCondition,
  TestEndByQuota,
  TestItem,
  TestItemQuota,
  TestItemResult,
  TestRunnerStartData,
  WebSiteExtensionStartData,
} from 'src/api/testrunner/models';
import { TestInfo } from '../../models/test-info';
import { UrlParams } from 'src/app/models/url-params';
import { CompleteTestInfo } from '../../models/complete-test-info';
import { EyeTrackerInfo } from '../../models/eye-tracker-info';

export const backendErrorsAction = createAction(ActionTypes.BACKEND_ERRORS, props<{ error: HttpErrorResponse }>());

export const emptyAction = createAction(ActionTypes.EMPTY);

export const finishMobileTestAction = createAction(ActionTypes.FINISH_MOBILE_TEST);

export const finishSessionAction = createAction(ActionTypes.FINISH_SESSION);
export const finishSessionSuccessAction = createAction(ActionTypes.FINISH_SESSION_SUCCESS);

export const finishTestAction = createAction(
  ActionTypes.FINISH_TEST,
  props<{ answers?: Array<TestItemResult>; completeTestInfo: CompleteTestInfo; testInfo: TestInfo | null; url: string | null }>(),
);

export const getPromoCodeAction = createAction(ActionTypes.GET_PROMO_CODE, props<{ testItemId: string }>());
export const getPromoCodeSuccessAction = createAction(
  ActionTypes.GET_PROMO_CODE_SUCCESS,
  props<{ promoCode: string | null; testItemId: string }>(),
);
export const getPromoCodeErrorAction = createAction(
  ActionTypes.GET_PROMO_CODE_ERROR,
  props<{ error: HttpErrorResponse; testItemId: string }>(),
);

export const getSessionCacheAction = createAction(ActionTypes.GET_SESSION_CACHE, props<{ urlParams: UrlParams }>());

export const getTokenAction = createAction(ActionTypes.GET_TOKEN, props<{ urlParams: UrlParams }>());

export const getWebSiteExtensionStartDataAction = createAction(
  ActionTypes.GET_WEB_SITE_EXTENSION_START_DATA,
  props<{ testId: string; testItemId?: string; apiVersion?: string }>(),
);
export const getWebSiteExtensionStartDataSuccessAction = createAction(
  ActionTypes.GET_WEB_SITE_EXTENSION_START_DATA_SUCCESS,
  props<{ webSiteExtensionStartData: WebSiteExtensionStartData }>(),
);

export const reorderLayoutItems = createAction(ActionTypes.REORDER_LAYOUT_ITEMS, props<{ reorderedItems: Array<TestItem> }>());

export const setSessionCacheAction = createAction(ActionTypes.SAVE_SESSION_CACHE, props<{ testId: string }>());
export const setSessionCacheSuccessAction = createAction(ActionTypes.SAVE_SESSION_CACHE_SUCCESS);

export const screenOutAction = createAction(
  ActionTypes.SCREEN_OUT,
  props<{
    answers?: Array<TestItemResult>;
    screenOutItem: TestEndByCondition | TestEndByQuota | TestItemQuota;
    testInfo: TestInfo | null;
  }>(),
);

export const scriptErrorsAction = createAction(ActionTypes.SCRIPT_ERROR, props<{ error: Error; sourceItemId?: string }>());

export const setEyeTrackerInfo = createAction(ActionTypes.SET_EYETRACKER_INFO, props<{ eyeTrackerInfo?: EyeTrackerInfo | null }>());

export const setMediaDevices = createAction(
  ActionTypes.SET_MEDIA_DEVICES,
  props<{ cameraDeviceId?: string | null; microphoneDeviceId?: string | null }>(),
);

export const setLoaderStatus = createAction(ActionTypes.SET_LOADER_STATUS);

export const setTestInfoAction = createAction(ActionTypes.SET_TEST_INFO, props<{ testInfo: TestInfo | null }>());
export const setTestInfoSuccessAction = createAction(ActionTypes.SET_TEST_INFO_SUCCESS, props<{ testInfo: TestInfo | null }>());

export const startMobileSessionAction = createAction(
  ActionTypes.START_MOBILE_SESSION,
  props<{
    testRunnerStartData: TestRunnerStartData;
    urlParams: UrlParams;
  }>(),
);

export const startSessionAction = createAction(ActionTypes.START_SESSION, props<{ urlParams: UrlParams }>());
export const startSessionErrorAction = createAction(
  ActionTypes.START_SESSION_ERROR,
  props<{
    completeTestInfo: CompleteTestInfo | null;
    testRunnerStartData: TestRunnerStartData;
    url: string | null;
  }>(),
);
export const startSessionSuccessAction = createAction(
  ActionTypes.START_SESSION_SUCCESS,
  props<{
    answersQueue: Array<TestItemResult>;
    isMobileAppInstalled: boolean;
    testRunnerStartData: TestRunnerStartData;
    urlParams: UrlParams;
  }>(),
);

export const stopVideoRecordingAction = createAction(ActionTypes.STOP_VIDEO_RECORDING);
