import { Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-mobile-app-install',
  templateUrl: './mobile-app-install.component.html',
  styleUrls: ['./mobile-app-install.component.scss'],
})
export class MobileAppInstallComponent {
  @Input() readonly os!: string;
  @Input() private readonly appUri!: string;
  @Input() private readonly appInstallPageUrl!: string;

  isAppInstalled = false;
  private readonly window: (WindowProxy & typeof globalThis) | null;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = document.defaultView;
  }

  openApp(): void {
    this.window?.open(this.appUri);
  }

  openInstallAppPage(): void {
    this.window?.open(this.appInstallPageUrl);
    this.isAppInstalled = true;
  }
}
