import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule as PrimengSharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { MobileAppInstallComponent } from './mobile-app-install/mobile-app-install.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [MobileAppInstallComponent, SafeHtmlPipe],
  imports: [ButtonModule, CommonModule, PrimengSharedModule, RippleModule, TranslocoModule],
  exports: [MobileAppInstallComponent, SafeHtmlPipe],
})
export class SharedModule {}
