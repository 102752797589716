<div class="app-install-info" *transloco="let t">
  <h3>{{ t('mobileAppInstall.header') }}</h3>

  <ol class="list-circle">
    <li class="flex flex-column">
      <span>{{ t('mobileAppInstall.installApp') }}</span>
      <p-button
        pRipple
        class="p-button-success mt-2"
        styleClass="w-full py-0 app-button"
        (click)="openInstallAppPage()"
      >
        <ng-template pTemplate="content">
          <div class="flex flex-row justify-content-center align-items-center font-medium w-full">
            <img class="os-icon" alt="" src="assets/images/installApp_{{os}}.svg"/>
            <span>{{ t('mobileAppInstall.goToInstall') }}</span>
          </div>
        </ng-template>
      </p-button>
    </li>
    <li>{{ t('mobileAppInstall.afterInstall') }}</li>
    <li class="flex flex-column">
      <span>{{ t('mobileAppInstall.pressButton') }}</span>
      <p-button
        pRipple
        class="p-button-success mt-2"
        styleClass="w-full py-0 app-button"
        (click)="openApp()"
        [disabled]="!isAppInstalled"
        [label]="t('mobileAppInstall.startTest')"
      ></p-button>
    </li>
  </ol>
</div>
